<template>
  <div class="card" :class="customClass">
    <div class="cardHeader">{{ title }}</div>
    <div class="grid-cell">
      <div class="grid-content">
        <div v-if="imgSrc" class="img-wrapper"><img :src="imgSrc" /></div>
        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        <div v-if="subtitleBold" class="subtitleBold">{{ subtitleBold }}</div>
        <div v-if="contentText" class="contentText">{{ contentText }}</div>
        <div v-if="listContent" class="list-wrapper">
          <ul v-for="item in listContent">
            <li>
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["imgSrc", "title", "subtitle", "subtitleBold", "contentText", "listContent", "customClass"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.card{
  border-radius: 8px 8px 48px 8px;
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
} 
.cardHeader{
  margin-bottom:1em;
  font-size: 18px;
  font-weight: 600;
}
.grid-cell {

}

.grid-content {
  padding-left: 20px;
  padding-right:20px;
}

.subtitle {
  padding-bottom: 10px;
}
.subtitleBold{
  margin-bottom: 5px;
  font-weight: 600;
}
.contentText{
  margin-bottom: 1em;
}

.list-wrapper {
  margin-top: 10px;

  ul{
    margin:0;
    padding-left: 25px;
  }
}
</style>