<template>
  <div class="grid">
    <div class="grid-cell flex-3" v-for="card in cards">
      <p class = "content-title">{{ card.contentTitle }}</p>
      <div class="grid-content">
        <h1>{{ card.title }}</h1>
        <p>{{ card.subtitle }}</p>
        <p class="subtitleBold">{{ card.subtitleBold }}</p>
        <p style="margin-top:0">{{ card.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cards"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 15% 0 15%;
}

.grid-cell {
  @include bg-color(takeda-white);
  border-radius: 8px 8px 48px 8px;
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
  overflow: hidden;
  position: relative;
  margin: 15px;
  padding: 20px;
  min-width: 190px;
}

.grid-content {
  border-left: 5px solid rgba(29, 99, 207, 0.7);
  padding-left: 20px;
}

p {
  @include color(slalom-dark-gray);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

h1 {
  color: $font-color-2;
  font-size: 42px;
}

.flex-3 {
  flex-basis: 20%;
}

.content-title {
  @include color(takeda-dark-grey);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .2rem;
}

.subtitleBold {
  @include color(takeda-dark-grey);
  margin-bottom: 5px;
  font-weight: 600;
}
</style>
